const links = [
    {
        name: 'Home',
        link: '/'
    },
    {
        name: 'Blog',
        link: '/blog'
    }
]

export default links;